// common---
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicsContactForm from "../components/contactForm/dynamicsContactForm"
import MediaKit from "../components/mediaKit"
// --- end common

import BannerSection from "../components/bannerSection"
import MultiUp from "../components/multiUp"
import MultiUpItem from "../components/multiUpItem"

// Image imports
import earlyLeadImage from "../images/cce-curious-george.png"
import socialLeadImage from "../images/cce-arthur.png"
import steamLeadImage from "../images/cce-ruff.png"

const earlyHeader = {
  title: "Early Childhood Education",
  image: earlyLeadImage,
}

const socialHeader = {
  title: "Social Emotional Learning",
  image: socialLeadImage,
}

const steamHeader = {
  title: "STEAM",
  image: steamLeadImage,
}

const CCEPage = () => (
  <Layout>
    <Seo
      title="Commonwealth Champions of Education"
      siteTitle="GBH Sponsorship"
      description="Become a GBH corporate sponsor and champion of education through the Commonwealth Champions of Education initiative—helping children achieve their highest potential."
    />

    <BannerSection
      category="cce"
      header="Become a Corporate Sponsor and Champion of Education"
      subHeader="The Commonwealth Champions of Education initiative helps GBH create engaging educational content that uses the power of mass media to help children (and the adults in their lives) achieve their highest potential."
    />

    <div className="list-section">
      <MultiUp type="three-up">
        <MultiUpItem header={earlyHeader} content="" />
        <MultiUpItem header={socialHeader} content="" />
        <MultiUpItem header={steamHeader} content="" />
      </MultiUp>
    </div>

    <div className="clearAll"></div>

    <div className="wrapper cce-content">
      <h2 class="header">Sponsor Benefits</h2>
      <h3 class="subheader">
        <span>
          Inclusion on the honor roll of Champions and acknowledgment on:
        </span>
        GBH 2, GBH 44, EXPLORE GBH and gbh.org
      </h3>
      <p>
        The frequency of acknowledgements will be based on level of support.
      </p>
    </div>

    <MediaKit />
    <DynamicsContactForm />
  </Layout>
)

export default CCEPage
